// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-page-en-js": () => import("../src/templates/index-page.en.js" /* webpackChunkName: "component---src-templates-index-page-en-js" */),
  "component---src-templates-project-page-js": () => import("../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-project-page-en-js": () => import("../src/templates/project-page.en.js" /* webpackChunkName: "component---src-templates-project-page-en-js" */),
  "component---src-templates-index-page-js": () => import("../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

